<template>
  <div>
    <template>
      <section id="dashboard-ecommerce">
        <b-row class="match-height">
          <b-col
            xl="4"
            md="4"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
          <b-col
            xl="8"
            md="8"
          >
            <ecommerce-statistics :data="data.statisticsItems" />
          </b-col>
        </b-row>

        <b-row class="match-height">
          <!-- Revenue Report Card -->
          <b-col lg="8">
            <ecommerce-revenue-report :data="data.revenue" />
          </b-col>
          <!--/ Revenue Report Card -->

          <b-col lg="4">
            <b-row class="match-height">
              <!-- Bar Chart - Orders -->
              <b-col
                lg="6"
                md="3"
                cols="6"
              >
                <ecommerce-order-chart :data="data.statisticsOrder" />
              </b-col>
              <!--/ Bar Chart - Orders -->
              <b-col
                lg="6"
                md="3"
                cols="6"
              >
                <ecommerce-profit-chart :data="data.statisticsProfit" />
              </b-col>
              <b-col
                lg="12"
                md="6"
              >
                <campaign-expenses-chart :data="data.earningsChart" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="match-height">
          <!-- Company Table Card -->
          <b-col lg="12">
            <ecommerce-company-table :table-data="data.companyTable" />
          </b-col>
          <!--/ Company Table Card -->

          <!-- Browser States Card -->
          <b-col
            lg="6"
            md="6"
          >
            <ecommerce-browser-states />
          </b-col>
          <!--/ Browser States Card -->

          <!-- Browser States Card -->
          <b-col
            lg="6"
            md="6"
          >
            <ecommerce-browser-states-products />
          </b-col>
          <!--/ Browser States Card -->

          <!-- Company Table Card -->
          <b-col lg="12">
            <ecommerce-company-table-seconde :table-data="data.companyTable" />
          </b-col>
          <!--/ Company Table Card -->
        </b-row>
      </section>
    </template>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { BRow, BCol } from "bootstrap-vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import CampaignExpensesChart from "./CampaignExpensesChart.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";
import EcommerceCompanyTableSeconde from "./EcommerceCompanyTableSeconde.vue";
import EcommerceBrowserStates from "./EcommerceBrowserStates.vue";
import EcommerceBrowserStatesProducts from "./EcommerceBrowserStatesProducts.vue";

export default {
  components: {
    BRow,
    BCol,

    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    CampaignExpensesChart,
    EcommerceCompanyTable,
    EcommerceCompanyTableSeconde,
    EcommerceBrowserStates,
    EcommerceBrowserStatesProducts,
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    // data
    // eslint-disable-next-line arrow-parens
    this.$http.get("/ecommerce/data").then((response) => {
      this.data = response.data;
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
